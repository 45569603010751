import { DateTime, Settings as LuxonSettings } from 'luxon'


LuxonSettings.defaultZone = 'Europe/Prague'
LuxonSettings.defaultLocale = 'en-US'


export function formatPrice (price?: number): string {
  return price ? price.toLocaleString('en-US', {
    style: 'currency',
    minimumFractionDigits: 0,
    currency: 'CZK',
  }) : ''
}

export function formatDistance (distance?: number): string {
  return distance !== undefined ? distance.toLocaleString('en-US', {
    style: 'unit',
    unit: 'kilometer',
    maximumFractionDigits: 0,
  }) : ''
}

export function formatDateTime (dateTime?: string, opts?: Intl.DateTimeFormatOptions): string | undefined {
  if (!dateTime) return undefined
  const dt = DateTime.fromISO(dateTime)
  if (!dt.isValid) return undefined

  return dt.toLocaleString({
    dateStyle: 'full',
    timeStyle: 'short',
    ...opts,
  })
}

import { useLocalStorage } from '@vueuse/core'
import { useCarClasses } from './content'


/* const currencies: {  } = useRuntimeConfig().public.currencies

export const useCurrentCurrency = () => useSessionStorage('currency', () => {
  return 'czk'
}, { writeDefaults: true }) */

export type OrderPlace = {
  placeId: string,
  mainText: string,
  secondaryText: string,
  location: { lat: number, lng: number },
  gMapsUrl: string,
  types: string[],
}

/**
 * Represents an order.
 *
 * Properties are optional, because the order can be created in multiple steps and starts as an empty object.
 */
export type Order = {
  /** The type of the order. */
  type?: 'oneway' | 'hourly' | 'daily'
  /** The start time in an ISO format. */
  startTime?: string
  /** The starting place. */
  from?: OrderPlace
  /** Only if type is oneway. Distance on the road in km. */
  distance?: number
  /** Only if type is oneway. */
  to?: OrderPlace
  /** Only if type is hourly. Time on the road in hours. */
  duration?: number
  /** Only if type is daily.  */
  durationDays?: number
  /** The price amount calculated by the calculatePrice(order: Order). */
  priceAmount?: number
  /** The car class slug. */
  carClass?: string
  /** Amount of passangers */
  passengers?: number
  /** Amount of luggage */
  luggage?: number
  /** The email address of the person making the order. */
  email?: string
  /** The name of the passanger. */
  name?: string
  /** The phone number of the passanger. */
  phone?: string
  /** Additional notes */
  notes?: string
}

export const exampleOrder: Order = {
  type: 'oneway',
  passengers: 1,
  luggage: 1,
  carClass: 'first',
  from: {
    placeId: 'ChIJyw1526e_C0cRrPakrNGArS8',
    mainText: 'Václav Havel Airport Prague',
    secondaryText: 'Aviatická, 161 00 Praha 6, Czechia',
    location: { lat: 50.10179100000001, lng: 14.2631811 },
    gMapsUrl: 'https://maps.google.com/?cid=3435543728786568876',
    types: ['airport', 'point_of_interest', 'establishment'],
  },
  to: {
    placeId: 'ChIJJaw6-pjkE0cRUxRGTh4or0U',
    mainText: 'Ostravar Aréna',
    secondaryText: 'Ruská 3077 /135, Zábřeh, 700 30 Ostrava-Ostrava-jih, Czechia',
    location: { lat: 49.80494789999999, lng: 18.2484347 },
    gMapsUrl: 'https://maps.google.com/?cid=5021276220168737875',
    types: ['stadium', 'point_of_interest', 'establishment'],
  },
  distance: 350,
  priceAmount: 1500,
  startTime: '2024-08-01T18:02:00.000Z',
  name: 'Jan Novák',
  phone: '+420777777777',
  email: 'jan@example.com',
  notes: 'First line\nsecond line',
}


export const useOrder = () => toReactive(useLocalStorage<Order>('order', {}))


/**
 * Calculate order price, if order is complete enough.
 *
 * @throws {Error} with cause incomplete_order if order is incomplete.
 */
export function calculatePrice (order: Order): number | undefined {
  if (order.type === undefined) return undefined

  const carClass = useCarClasses().find(c => c.id === order.carClass)
  if (!carClass) throw new Error('Unknown car class', { cause: 'incomplete_order' })

  let price = 0

  function getPriceMultiplier (distance: number) {
    if (distance <= 100) return 1.5;
    if (distance <= 150) return 1.4;
    if (distance <= 200) return 1.3;
    if (distance <= 500) return 1.2;
    if (distance <= 600) return 1.1;
    return 1;
  }

  // Route
  if (order.type === 'oneway') {
    if (!order.distance) throw new Error('Missing distance', { cause: 'incomplete_order' })
    price = carClass.priceKm * order.distance * getPriceMultiplier(order.distance)
  }
  else if (order.type === 'hourly') {
    if (!order.duration) throw new Error('Missing duration', { cause: 'incomplete_order' })
    price = carClass.priceHour * order.duration
  }
  else if (order.type === 'daily') {
    if (!order.durationDays) throw new Error('Missing duration days', { cause: 'incomplete_order' })
    price = carClass.priceDay * order.durationDays
  }
  else {
    throw new Error('Unknown order type', { cause: 'incomplete_order' })
  }

  // Min price
  price = Math.max(price, carClass.priceMin)

  // Round up
  const remainder = price % 50
  if (remainder > 0)
    price += 50 - remainder

  return price
}


/**
 * Validate if place is in allowed boundaries.
 */
export function isPlaceAllowedBoundaries (place: OrderPlace): boolean {
  const allowedBounds = useAppConfig().businessArea

  if (place.location.lat < allowedBounds.minLat || place.location.lat > allowedBounds.maxLat) return false
  if (place.location.lng < allowedBounds.minLng || place.location.lng > allowedBounds.maxLng) return false

  return true
}


/**
 * Validate if place is specific enough.
 */
export function isPlaceSpecificEnough (place: OrderPlace) {
  return ['street_address', 'point_of_interest', 'premise', 'subpremise'].some(t => place.types.includes(t))
}


/**
 * Remove perpetual properties and keep reusable properties (useable for next rides).
 */
export function cleanOrder (order: Order) {
  order.carClass = undefined
  order.priceAmount = undefined
  order.startTime = undefined
  order.notes = undefined
}
